import React, { useContext, useEffect } from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import { GlobalStateContext } from "../../context/globalContext";
import { wrapper } from "./SubPage.module.scss";
import { Helmet } from "react-helmet";

const SubPage = (props) => {
  const { pageContext } = props;
  const { lastUpdate, subPage } = pageContext;
  const { setUpdatedAt, updatedAt } = useContext(GlobalStateContext);
  console.log(subPage);
  useEffect(() => {
    lastUpdate !== updatedAt && setUpdatedAt(lastUpdate);
  }, []);

  return subPage ? (
    <>
      <Helmet title={`${subPage.title}`} />
      <div className={wrapper}>
        <BlockContent blocks={subPage.components} />
      </div>
    </>
  ) : null;
};

export default SubPage;
